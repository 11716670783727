




















import { Component, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    ButtonBase,
  },
})
export default class SignIn3 extends Vue {}
